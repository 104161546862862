$background-color_1: rgb(242, 240, 237);

.dev-portal-custom-style {
	overflow-wrap: anywhere;
	td {
		>p {
			margin: 0 !important;
		}
	}
	img {
		max-width: 100% !important;
		height: auto !important;
	}
	table {
		box-sizing: border-box;
	}
	tr {
		text-align: left;
		padding: 15px;
	}
	th {
		text-align: left;
		padding: 15px;
	}
	td {
		text-align: left;
		padding: 15px;
	}
.table-without-thead {
	.table-with-thead {
		* {
			margin: 0 !important;
		}
	}
	tr {
		&:nth-child(odd) {
			background-color: $background-color_1;
		}
		&:first-child {
			>td {
				&:first-child {
					border-left: 1px solid rgb(147, 140, 133);
					border-top-left-radius: 0.25rem;
				}
				border-top: 1px solid rgb(147, 140, 133);
				border-bottom: 1px solid rgb(147, 140, 133);
				border-right: 1px solid rgb(147, 140, 133);
				&:last-child {
					border-top-right-radius: 0.25rem;
				}
			}
			background: rgb(247, 246, 244) !important;
			font-weight: bold;
		}
		&:not(tr:first-child) {
			>td {
				border-bottom: 1px solid rgb(147, 140, 133);
				border-right: 1px solid rgb(147, 140, 133);
				&:first-child {
					border-left: 1px solid rgb(147, 140, 133);
				}
			}
		}
		&:last-child {
			>td {
				&:first-child {
					border-bottom-left-radius: 0.25rem;
				}
				&:last-child {
					border-bottom-right-radius: 0.25rem;
				}
			}
		}
	}
}
.table-with-thead {
	th {
		border-top: 1px solid rgb(147, 140, 133);
		border-bottom: 1px solid rgb(147, 140, 133);
		border-right: 1px solid rgb(147, 140, 133);
		&:last-child {
			border-right: 1px solid rgb(147, 140, 133);
			border-top-right-radius: 0.25rem;
		}
		&:first-child {
			border-left: 1px solid rgb(147, 140, 133);
			border-top-left-radius: 0.25rem;
		}
	}
	td {
		border-bottom: 1px solid rgb(147, 140, 133);
		border-right: 1px solid rgb(147, 140, 133);
	}
	tr {
		>td {
			&:first-child {
				border-left: 1px solid rgb(147, 140, 133);
			}
		}
		&:last-child {
			>td {
				&:first-child {
					border-bottom-left-radius: 0.25rem;
				}
				&:last-child {
					border-bottom-right-radius: 0.25rem;
				}
			}
		}
		&:nth-child(even) {
			background: rgb(242, 240, 237);
		}
	}
	thead {
		>tr {
			&:first-child {
				background: rgb(247, 246, 244) !important;
				font-weight: bold;
			}
		}
	}
}
}


.table-of-contents {
    overflow: hidden auto;
    max-height: calc(100vh - 300px);
}